import React, { useState, useEffect, useRef } from "react";

import { Tabs, Tab, Card, CardHeader, CardBody, CardFooter, Image, Button, Tooltip, CheckboxGroup, Checkbox, useDisclosure, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Link, RadioGroup, Radio, Input } from "@nextui-org/react";

import { useAuth } from "../context/auth_context";
import { useAlert } from "../context/alert_context";

import { creditPricing } from "../constants/api_endpoint_info";

export function CreditPackage() {
    const {
        address,
        truncateAddress,
        truncateTelegram,
        signedIn,
        signedInLoading,
        isVerifyingLoading,
        apiAccountData,
        handleSignIn,
        handleSignOut,
        handleOpen,
        handleDisconnect,
        createNewApiAccount,
        createNewApiAccountLoading,
        signUpTelegramUserData,
        setSignUpTelegramUserData,
        requestTrialAccount,
        requestTrialAccountLoading,
        handleCreditPurchase,
        creditPurchaseLoading,

        discountCodeValidationLoading, discountCodePercent, handleDiscountCodeCheck
    } = useAuth()
    const { showToast } = useAlert();

    const [featureSelected, setFeatureSelected] = useState(["one", "two", "three"]);

    const [creditState, setCreditState] = useState({
        selectedCredits: null,
        selectedCreditsTitle: null,
        selectedPrice: null,
        selectedPriceTitle: null,
        paymentType: "usdc"
    });

    const discountCodeRef = useRef(null);
    const handleDiscountCodeSubmit = () => {
        const discountCode = discountCodeRef.current.value;
        console.log('discount code', discountCode)
        handleDiscountCodeCheck(discountCode);
    };

    const handleBuyClick = (item, discountedPrice) => {
        console.log(item);
        const updatedItem = {
            ...item,
            payment_type: creditState.paymentType,
            package_type: 'credit',
            price: discountedPrice
        };
        handleCreditPurchase(updatedItem)
    };

    useEffect(() => {
        console.log(creditState);
    }, [creditState]);

    // const displayedCreditPricing = creditPricing.filter(
    //     (item) => !item.telegram_username || item.telegram_username === apiAccountData?.telegram_username
    // );
    const displayedCreditPricing = creditPricing.filter(
        // (item) => !item.wallet_address || (signedIn && item.wallet_address === address)
        (item) => !item.wallet_address || (signedIn && item.wallet_address.toLowerCase() === address.toLowerCase())
    );

    useEffect(() => {
        console.log("Final displayed packages for user:", displayedCreditPricing);
    }, [displayedCreditPricing]);

    return (
        <>
            <p className="text-5xl">Credit Packages</p>
            <p className="text-sm text-default-500">Starting at $100</p>

            <CheckboxGroup
                label="Features"
                // defaultValue={["one", "two", "three"]}
                value={featureSelected}
                onValueChange={setFeatureSelected}
                className="mt-10"
            >
                <Checkbox color="success" defaultSelected isSelected isReadOnly className="cursor-default" value="one">No expiration</Checkbox>
                <Checkbox color="success" defaultSelected isSelected isReadOnly className="cursor-default" value="two">Pay-as-you-go pricing</Checkbox>
                <Checkbox color="success" defaultSelected isSelected isReadOnly className="cursor-default" value="three"><Link className="" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://t.co/KiaAdQq7hV">Public forum support</Link></Checkbox>
            </CheckboxGroup>

            <div className="mt-10">
                <RadioGroup
                    label="Payment Method"
                    value={creditState.paymentType}
                    onValueChange={(newPaymentType) => setCreditState(prevState => ({ ...prevState, paymentType: newPaymentType }))}
                    orientation="horizontal"
                    size="sm"
                    color="success"
                >
                    {/* <Radio value="ethereum">Ethereum</Radio> */}
                    <Radio value="usdc">USDC</Radio>
                    <Radio value="usdt">USDT</Radio>
                </RadioGroup>
            </div>

            <div className="mt-10">
                <p className="text-default-500 mb-2">
                    Discount Code {discountCodePercent !== null && <span className="text-green-500">({(discountCodePercent * 100)}% discount applied)</span>}
                </p>

                {discountCodePercent === null && (
                    <div className="flex gap-2 w-full sm:max-w-xs">
                        <Input
                            ref={discountCodeRef}
                            variant="bordered"
                            type="text"
                            size="sm"
                            placeholder="70T04P1"
                        />
                        <Button
                            color="primary"
                            size="sm"
                            isLoading={discountCodeValidationLoading}
                            isDisabled={discountCodeValidationLoading}
                            onClick={handleDiscountCodeSubmit}
                        >
                            {discountCodeValidationLoading ? null : "Submit"}
                        </Button>
                    </div>
                )}
            </div>

            <div className="gap-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 mt-10">
                {displayedCreditPricing.map((item, index) => {
                    const discountedPrice = discountCodePercent
                        ? (item.price * (1 - discountCodePercent)).toFixed(2) // Apply discount
                        : item.price; // Regular price if no discount
                    return (
                        <Card
                            shadow="sm"
                            key={index}
                            className="h-[140px] sm:flex-grow"
                            // style={{ backgroundImage: `url(${item.img})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                            style={item.img ? { backgroundImage: `url(${item.img})`, backgroundSize: 'cover', backgroundPosition: 'center' } : {}}
                        >
                            <CardHeader className="flex flex-col items-start absolute">
                                <h4 className="text-white font-medium text-5xl">
                                    {item.credit_title}
                                    <span className="text-2xl text-white opacity-50">
                                        (${discountedPrice})
                                    </span>
                                </h4>
                            </CardHeader>
                            <CardBody>
                            </CardBody>
                            <CardFooter>
                                {signedIn ? (
                                    <Button
                                        size="sm"
                                        onClick={() => handleBuyClick(item, discountedPrice)}
                                        isDisabled={creditPurchaseLoading}
                                        isLoading={creditPurchaseLoading}
                                    >
                                        {creditPurchaseLoading ? null : "Purchase"}
                                    </Button>
                                ) : (
                                    <Button
                                        as={Link}
                                        href="/login"
                                        size="sm"
                                    >
                                        Login
                                    </Button>
                                )}
                            </CardFooter>
                        </Card>
                    );
                })}

                {/* {creditPricing.map((item, index) => (
                    <Card
                        shadow="sm"
                        key={index}
                        // isPressable 
                        // onClick={onOpen}
                        // isFooterBlurred
                        className="h-[140px] sm:flex-grow"
                        style={{ backgroundImage: `url(${item.img})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                    >
                        <CardHeader className="flex flex-col items-start absolute">
                            <h4 className="text-white font-medium text-5xl">{item.credit_title}<span className="text-2xl text-white opacity-50">({item.price_title})</span></h4>                    
                        </CardHeader>
                        <CardBody>
                        </CardBody>
                        <CardFooter>
                            {signedIn ? (
                                <Button
                                    size="sm"
                                    onClick={() => handleBuyClick(item)}
                                    isDisabled={creditPurchaseLoading}
                                    isLoading={creditPurchaseLoading}
                                >
                                    {creditPurchaseLoading ? null : "Purchase"}
                                </Button>
                            ) : (
                                <Button
                                    as={Link}
                                    href="/login"
                                    size="sm"
                                >
                                    Login
                                </Button>
                            )}
                        </CardFooter>
                    </Card>
                ))} */}
            </div>

            {/* <Modal 
                isOpen={isOpen} 
                onOpenChange={onOpenChange}
                // placement="top-center"
                backdrop="blur"
                isDismissable={false}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">
                                Login
                                <p className="text-xs text-default-500">
                                    Read the <Link className="text-xs" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://docs.wandbot.app/setting-up-wandbot">documentation</Link> for more detailed steps.
                                </p>
                            </ModalHeader>
                            <ModalBody>
                                {creditState.selectedCredits && (
                                    <>
                                        <p>Credits: {creditState.selectedCredits}</p>
                                        <p>Credits Title: {creditState.selectedCreditsTitle}</p>
                                        <p>Price: ${creditState.selectedPrice}</p>
                                        <p>Price Title: {creditState.selectedPriceTitle}</p>
                                    </>
                                )}
                            </ModalBody>
                            <ModalFooter>
                                footer
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal> */}
        </>
    );
}